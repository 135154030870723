import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./CalculateStyles.scss";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";
import SlideIn from "./SlideIn.js";

const SERVICE_NAMES = {
	GAME_ORGANIZATION: "GAME_ORGANIZATION",
	BROADCASTING: "BROADCASTING",
};

const SUB_SERVICE_NAMES = {
	REGULATIONS: "REGULATIONS",
	SERVER_INFRASTRUCTURE: "SERVER_INFRASTRUCTURE",
	REFEREES: "REFEREES",
	TEAM_CURATORS: "TEAM_CURATORS",
	BROADCAST_GRAPHICS: "BROADCAST_GRAPHICS",
	UNIQUE_GAME_HUD: "UNIQUE_GAME_HUD",
	LOGO_PLACEMENT_2D: "LOGO_PLACEMENT_2D",
	BRAND_LOGOS_3D: "BRAND_LOGOS_3D",
	COMMENTARY_STUDIO: "COMMENTARY_STUDIO",
};

function Calculate() {
	useEffect(() => {
		document.title = "Calculate | GLuck Esports";
	});
	const { t, i18n } = useTranslation();
	const [format, setFormat] = useState("");
	const [duration, setDuration] = useState("");
	const [dateRecommendation, setDateRecommendation] = useState("");
	const [gameFormat, setGameFormat] = useState("");
	const [teamCount, setTeamCount] = useState("");
	const [services, setServices] = useState({
		[SERVICE_NAMES.GAME_ORGANIZATION]: false,
		[SERVICE_NAMES.BROADCASTING]: false,
	});
	const [totalCost, setTotalCost] = useState(0);
	const [showContactForm, setShowContactForm] = useState(false);
	const [step, setStep] = useState(1);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [telegram, setTelegram] = useState("");
	const [phone, setPhone] = useState("");
	const [additionalInfo, setAdditionalInfo] = useState("");
	const [validationErrors, setValidationErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [additionalOptions, setAdditionalOptions] = useState({
		[SERVICE_NAMES.GAME_ORGANIZATION]: {
			[SUB_SERVICE_NAMES.REGULATIONS]: false,
			[SUB_SERVICE_NAMES.SERVER_INFRASTRUCTURE]: false,
			[SUB_SERVICE_NAMES.REFEREES]: false,
			[SUB_SERVICE_NAMES.TEAM_CURATORS]: false,
		},
		[SERVICE_NAMES.BROADCASTING]: {
			[SUB_SERVICE_NAMES.BROADCAST_GRAPHICS]: false,
			[SUB_SERVICE_NAMES.UNIQUE_GAME_HUD]: false,
			[SUB_SERVICE_NAMES.LOGO_PLACEMENT_2D]: false,
			[SUB_SERVICE_NAMES.BRAND_LOGOS_3D]: false,
			[SUB_SERVICE_NAMES.COMMENTARY_STUDIO]: false,
		},
	});
	const navigate = useNavigate();
	const [counter, setCounter] = useState(7);
	const [isOptionActive, setIsOptionActive] = useState(false);
	const showTooltip = (event, content) => {
		if (window.innerWidth <= 768) {
			alert(content);
		}
	};
	const [durationMultiplier, setDurationMultiplier] = useState(1);

	const COSTS = useMemo(
		() => ({
			DURATION: {
				SHORT: 1,
				MEDIUM: 2,
				LONG: 3,
				EXTENDED: 4,
			},
			SERVICES: {
				[SERVICE_NAMES.GAME_ORGANIZATION]: 0,
				[SERVICE_NAMES.BROADCASTING]: 100000,
			},
			ADDITIONAL_OPTIONS: {
				[SUB_SERVICE_NAMES.REGULATIONS]: 10000,
				[SUB_SERVICE_NAMES.SERVER_INFRASTRUCTURE]: 20000,
				[SUB_SERVICE_NAMES.REFEREES]: 25000,
				[SUB_SERVICE_NAMES.TEAM_CURATORS]: 20000,
				[SUB_SERVICE_NAMES.BROADCAST_GRAPHICS]: 150000,
				[SUB_SERVICE_NAMES.UNIQUE_GAME_HUD]: 110000,
				[SUB_SERVICE_NAMES.LOGO_PLACEMENT_2D]: 50000,
				[SUB_SERVICE_NAMES.BRAND_LOGOS_3D]: 500000,
				[SUB_SERVICE_NAMES.COMMENTARY_STUDIO]: 50000,
			},
		}),
		[]
	);

	const conversionRates = useMemo(
		() => ({
			GAME_ORGANIZATION: 0.01,
			BROADCASTING: 0.01,
			REGULATIONS: 0.01,
			SERVER_INFRASTRUCTURE: 0.01,
			REFEREES: 0.01,
			TEAM_CURATORS: 0.01,
			BROADCAST_GRAPHICS: 0.01,
			UNIQUE_GAME_HUD: 0.01,
			LOGO_PLACEMENT_2D: 0.01,
			BRAND_LOGOS_3D: 0.01,
			COMMENTARY_STUDIO: 0.01,
		}),
		[]
	);

	const OPTION_UNKNOWN = t("calculate.optionUnknown");
	const OPTION_MORE_THAN_MONTH = t("calculate.optionMoreThanMonth");
	const OPTION_YES = t("calculate.optionYes");
	const OPTION_NO_SPECIFIC_DATES = t("calculate.optionNoSpecificDates");
	const handleTeamCountChange = (value) => {
		// Clear previous team count errors
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			teamCount: null,
		}));

		if (teamCount === value) {
			setTeamCount(null);
			setIsOptionActive(false); // Optional if needed for visual indication
			return;
		}

		if (value === "OPTION_UNKNOWN") {
			setTeamCount("OPTION_UNKNOWN");
			setIsOptionActive(true);
			return;
		}

		const numericValue = value.replace(/\D/g, "");

		if (!numericValue) {
			setValidationErrors((prevErrors) => ({
				...prevErrors,
				teamCount: t("calculate.invalidInput"),
			}));
			return;
		}

		const parsedValue = parseInt(numericValue, 10);
		if (parsedValue < 2 || parsedValue > 256) {
			setValidationErrors((prevErrors) => ({
				...prevErrors,
				teamCount: t("calculate.exceedsMaximum"),
			}));
			return;
		}

		setTeamCount(parsedValue);
		setIsOptionActive(true);
	};

	const handlePhoneFocus = () => {
		if (phone === "") {
			setPhone("+");
		}
	};

	const handleFormatChange = (selectedFormat) => {
		if (format === selectedFormat) {
			setFormat(null);
			setIsOptionActive(false);
		} else {
			setFormat(selectedFormat);
			setIsOptionActive(true);
		}

		// Clear validation errors in either case
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			format: null,
		}));
	};

	const handleDurationChange = (value) => {
		// Clear previous validation errors
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			duration: null,
		}));

		if (duration === value) {
			setDuration(null);
			setDurationMultiplier(null);
			setIsOptionActive(false);
			return;
		}

		if (value === OPTION_UNKNOWN) {
			setDuration(OPTION_UNKNOWN);
			setDurationMultiplier(5);
			setIsOptionActive(true);
			return;
		}

		if (value === OPTION_MORE_THAN_MONTH) {
			setDuration(OPTION_MORE_THAN_MONTH);
			setDurationMultiplier(30);
			setIsOptionActive(true);
			return;
		}

		// убираем нечисловые значения
		const numericValue = value.replace(/\D/g, "");

		if (!numericValue || parseInt(numericValue, 10) === 0) {
			setValidationErrors((prevErrors) => ({
				...prevErrors,
				duration: t("calculate.invalidInput"),
			}));
			return;
		}

		///проверка на макс значение
		const parsedValue = parseInt(numericValue, 10);
		if (parsedValue > 999) {
			setValidationErrors((prevErrors) => ({
				...prevErrors,
				duration: t("calculate.exceedsMaximum"),
			}));
			return;
		}
		//склонение
		setDuration(`${parsedValue} ${parsedValue === 1 ? "день" : "дня"}`);
		setDurationMultiplier(parsedValue);
		setIsOptionActive(true);
	};

	const handleDateRecommendation = (recommendation) => {
		if (dateRecommendation === recommendation) {
			setDateRecommendation(null);
			setIsOptionActive(false); // Optional if needed for visual indication
		} else {
			setDateRecommendation(recommendation);
			setIsOptionActive(true);
		}

		setValidationErrors((prevErrors) => ({
			...prevErrors,
			dateRecommendation: null,
		}));
	};

	const handleGameFormatChange = (selectedGameFormat) => {
		if (gameFormat === selectedGameFormat) {
			setGameFormat(null);
			setIsOptionActive(false); // Optional if needed for visual indication
		} else {
			setGameFormat(selectedGameFormat);
			setIsOptionActive(true);
		}

		setValidationErrors((prevErrors) => ({
			...prevErrors,
			gameFormat: null,
		}));
	};
	const SERVICES_CONFIG = useMemo(
		() => ({
			[SERVICE_NAMES.GAME_ORGANIZATION]: {
				displayName: t("services.gameOrganizationAndRefereeing"),
				basePrice: 0,
				hasMultiplier: true,
				options: {
					[SUB_SERVICE_NAMES.REGULATIONS]: {
						displayName: t("services.options.regulations"),
						hasMultiplier: true,
					},
					[SUB_SERVICE_NAMES.SERVER_INFRASTRUCTURE]: {
						displayName: t("services.options.serverInfrastructure"),
						hasMultiplier: true,
					},
					[SUB_SERVICE_NAMES.REFEREES]: {
						displayName: t("services.options.referees"),
						hasMultiplier: true,
					},
					[SUB_SERVICE_NAMES.TEAM_CURATORS]: {
						displayName: t("services.options.teamCurators"),
						hasMultiplier: true,
					},
				},
			},
			[SERVICE_NAMES.BROADCASTING]: {
				displayName: t("services.broadcasting"),
				basePrice: 100000,
				hasMultiplier: true,
				options: {
					[SUB_SERVICE_NAMES.BROADCAST_GRAPHICS]: {
						displayName: t("services.options.broadcastGraphics"),
						hasMultiplier: false,
					},
					[SUB_SERVICE_NAMES.UNIQUE_GAME_HUD]: {
						displayName: t("services.options.uniqueGameHUD"),
						hasMultiplier: false,
					},
					[SUB_SERVICE_NAMES.LOGO_PLACEMENT_2D]: {
						displayName: t("services.options.2DLogoPlacement"),
						hasMultiplier: false,
					},
					[SUB_SERVICE_NAMES.BRAND_LOGOS_3D]: {
						displayName: t("services.options.3DBrandLogos"),
						hasMultiplier: false,
					},
					[SUB_SERVICE_NAMES.COMMENTARY_STUDIO]: {
						displayName: t("services.options.commentaryStudioSelection"),
						hasMultiplier: true,
					},
				},
			},
		}),
		[t]
	);

	const calculateTotalCost = useCallback(() => {
		const isRussian = i18n.language === "ru";
		let totalWithMultiplier = 0;
		let totalFixed = 0;

		Object.entries(services).forEach(([serviceName, isSelected]) => {
			if (isSelected && SERVICES_CONFIG[serviceName]) {
				const serviceConfig = SERVICES_CONFIG[serviceName];
				const basePrice = serviceConfig.basePrice || 0;

				if (serviceConfig.hasMultiplier) {
					totalWithMultiplier += isRussian
						? basePrice
						: basePrice * conversionRates[serviceName];
				} else {
					totalFixed += isRussian
						? basePrice
						: basePrice * conversionRates[serviceName];
				}

				if (additionalOptions[serviceName]) {
					Object.entries(additionalOptions[serviceName]).forEach(
						([option, isOptionSelected]) => {
							if (isOptionSelected && serviceConfig.options[option]) {
								const optionConfig = serviceConfig.options[option];
								const optionPrice = COSTS.ADDITIONAL_OPTIONS[option] || 0;
								const convertedOptionPrice = isRussian
									? optionPrice
									: optionPrice * conversionRates[option];

								if (optionConfig.hasMultiplier) {
									totalWithMultiplier += convertedOptionPrice;
								} else {
									totalFixed += convertedOptionPrice;
								}
							}
						}
					);
				}
			}
		});

		const total = totalWithMultiplier * durationMultiplier + totalFixed;

		return isRussian ? Math.round(total) : Number(total.toFixed(2));
	}, [
		COSTS,
		durationMultiplier,
		services,
		additionalOptions,
		SERVICES_CONFIG,
		i18n.language,
		conversionRates,
	]);

	const handleAdditionalOptionToggle = (serviceName, optionName) => {
		setAdditionalOptions((prevOptions) => {
			const updatedOptions = {
				...prevOptions,
				[serviceName]: {
					...prevOptions[serviceName],
					[optionName]: !prevOptions[serviceName]?.[optionName],
				},
			};

			const anyOptionSelected =
				Object.values(services).some((value) => value) ||
				Object.values(updatedOptions).some((service) =>
					Object.values(service).some((value) => value)
				);

			if (anyOptionSelected) {
				setValidationErrors((prev) => ({ ...prev, services: null }));
			}

			return updatedOptions;
		});
	};

	const handleNext = () => {
		const errors = {};

		switch (step) {
			case 1:
				if (!format) errors.format = true;
				break;
			case 2:
				if (!duration) errors.duration = true;
				break;
			case 3:
				if (!dateRecommendation) errors.dateRecommendation = true;
				break;
			case 4:
				if (!gameFormat) errors.gameFormat = true;
				break;
			case 5:
				if (!teamCount) errors.teamCount = true;
				break;
			default:
				break;
		}

		if (Object.keys(errors).length === 0) {
			// Proceed to the next step if no errors
			setStep((prevStep) => prevStep + 1);
			setValidationErrors({});
		} else {
			// Set validation errors if any
			setValidationErrors(errors);
		}

		// Reset the active state of options
		setIsOptionActive(false);
	};

	const handlePrevious = () => {
		if (step > 1) setStep(step - 1);
	};

	const handleSubmit = async () => {
		let errors = {};

		if (!name.trim()) {
			errors.name = true;
		}

		if (!email.trim() && !telegram.trim()) {
			errors.email = true;
			errors.telegram = true;
		} else if (email && !isValidEmail(email)) {
			errors.email = true;
		}

		if (Object.keys(errors).length === 0) {
			console.log("Form submitted:", { name, email, telegram, additionalInfo });
			setIsSubmitted(true);

			// Send notification to Telegram
			const botToken = "7451473715:AAEzIcxwdonpB26vrEuXD0ttFVMVWHTIkd4"; // bot token
			const chatId = "-4289469759";
			const message = `New form submission:
  Name: ${name}
  Email: ${email}
  Telegram: ${telegram}
  Phone: ${phone}
  Additional Info: ${additionalInfo}
  Format: ${format}
  Duration(days): ${duration}
  Date Recommendation: ${dateRecommendation}
  Game Format: ${gameFormat}
  Team Count: ${teamCount}
  Total Cost: ${totalCost}`;

			try {
				const response = await fetch(
					`https://api.telegram.org/bot${botToken}/sendMessage`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							chat_id: chatId,
							text: message,
						}),
					}
				);

				if (!response.ok) {
					throw new Error("Failed to send Telegram notification");
				}

				console.log("Telegram notification sent successfully");
			} catch (error) {
				console.error("Error sending Telegram notification:", error);
			}
		} else {
			setValidationErrors(errors);
		}
	};

	const isValidEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	const buttons = document.querySelectorAll(".option-button");

	buttons.forEach((button) => {
		button.addEventListener("click", () => {
			button.classList.toggle("active");
		});
	});

	useEffect(() => {
		const newTotalCost = calculateTotalCost();
		setTotalCost(newTotalCost);
	}, [services, additionalOptions, format, duration, calculateTotalCost]);

	const handleServiceToggle = (serviceName) => {
		setServices((prevServices) => {
			const newServices = {
				...prevServices,
				[serviceName]: !prevServices[serviceName],
			};

			if (
				serviceName === SERVICE_NAMES.GAME_ORGANIZATION &&
				newServices[serviceName]
			) {
				setAdditionalOptions((prevOptions) => ({
					...prevOptions,
					[SERVICE_NAMES.GAME_ORGANIZATION]: Object.keys(
						SERVICES_CONFIG[SERVICE_NAMES.GAME_ORGANIZATION].options
					).reduce((acc, key) => {
						acc[key] = true;
						return acc;
					}, {}),
				}));
			}

			return newServices;
		});
	};

	useEffect(() => {
		setTotalCost(calculateTotalCost());
	}, [services, additionalOptions, format, duration, calculateTotalCost]);

	useEffect(() => {
		if (isSubmitted) {
			const timer =
				counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
			return () => clearInterval(timer);
		}
	}, [counter, isSubmitted]);

	useEffect(() => {
		const handleTooltipClick = (e) => {
			if (window.innerWidth <= 768) {
				const questionMark = e.target.closest(".question-mark");
				if (questionMark) {
					questionMark.classList.toggle("tooltip-active");
				}
			}
		};

		document.addEventListener("click", handleTooltipClick);

		return () => {
			document.removeEventListener("click", handleTooltipClick);
		};
	}, []);

	return (
		<section id="calculate" className="calculate-page">
			<Navbar isVisible={true} />
			{step === 1 && (
				<div className="slide">
					<SlideIn direction="up" delay={50}>
						<div className="slide-container">
							<h1>{t("calculate.eventFormatQuestion")}</h1>
							<div
								className={`options options-group ${
									validationErrors.format ? "error" : ""
								}`}
							>
								{[
									{ name: t("calculate.online") },
									{ name: t("calculate.lan") },
									{ name: t("calculate.onlineLan") },
									{ name: t("calculate.other") },
								].map((option, index) => (
									<div
										key={index}
										className={`option-button ${
											format === option.name ? "active" : ""
										}`}
										onClick={() => handleFormatChange(option.name)}
									>
										<span>{option.name}</span>
									</div>
								))}
							</div>
						</div>
					</SlideIn>
				</div>
			)}

			{step === 2 && (
				<div className="slide">
					<SlideIn direction="up" delay={50}>
						<div className="slide-container">
							<h1>{t("calculate.eventDurationQuestion")}</h1>
							<div
								className={`options options-group ${
									validationErrors.duration ? "error" : ""
								}`}
							>
								<input
									type="text"
									pattern="[0-9]*"
									onChange={(e) =>
										handleDurationChange(e.target.value.replace(/\D/g, ""))
									}
									placeholder={
										validationErrors.duration
											? validationErrors.duration
											: t("calculate.enterDays")
									}
									className={`option-button number-option ${
										validationErrors.duration ? "error-placeholder" : ""
									}`}
								/>

								<div
									className={`option-button ${
										duration === OPTION_UNKNOWN ? "active" : ""
									}`}
									onClick={() => handleDurationChange(OPTION_UNKNOWN)}
								>
									<span>{t("calculate.optionUnknown")}</span>
								</div>
								<div
									className={`option-button ${
										duration === OPTION_MORE_THAN_MONTH ? "active" : ""
									}`}
									onClick={() => handleDurationChange(OPTION_MORE_THAN_MONTH)}
								>
									<span>{t("calculate.optionMoreThanMonth")}</span>
								</div>
							</div>
						</div>
					</SlideIn>
				</div>
			)}
			{step === 3 && (
				<div className="slide">
					<SlideIn direction="up" delay={50}>
						<div className="slide-container">
							<h1>{t("calculate.dateRecommendationQuestion")}</h1>
							<div
								className={`options options-group ${
									validationErrors.dateRecommendation ? "error" : ""
								}`}
							>
								<div
									className={`option-button ${
										dateRecommendation === OPTION_YES ? "active" : ""
									}`}
									onClick={() => handleDateRecommendation(OPTION_YES)}
								>
									<span>{t("calculate.optionYes")}</span>
								</div>
								<div
									className={`option-button ${
										dateRecommendation === OPTION_NO_SPECIFIC_DATES
											? "active"
											: ""
									}`}
									onClick={() =>
										handleDateRecommendation(OPTION_NO_SPECIFIC_DATES)
									}
								>
									<span>{t("calculate.optionNoSpecificDates")}</span>
								</div>
							</div>
						</div>
					</SlideIn>
				</div>
			)}

			{step === 4 && (
				<div className="slide">
					<SlideIn direction="up" delay={50}>
						<div className="slide-container">
							<h1>{t("calculate.teamFormatQuestion")}</h1>
							<div
								className={`options options-group ${
									validationErrors.gameFormat ? "error" : ""
								}`}
							>
								{[
									{ name: t("calculate.2x2") },
									{ name: t("calculate.5x5") },
									{ name: t("calculate.other") },
								].map((option, index) => (
									<div
										key={index}
										className={`option-button ${
											gameFormat === option.name ? "active" : ""
										}`}
										onClick={() => handleGameFormatChange(option.name)}
									>
										<span>{option.name}</span>
									</div>
								))}
							</div>
						</div>
					</SlideIn>
				</div>
			)}

			{step === 5 && (
				<div className="slide">
					<SlideIn direction="up" delay={50}>
						<div className="slide-container">
							<h1>{t("calculate.teamCountQuestion")}</h1>
							<div
								className={`options options-group ${
									validationErrors.teamCount ? "error" : ""
								}`}
							>
								<input
									type="text"
									pattern="[0-9]*"
									inputMode="numeric"
									// value={teamCount}
									onChange={(e) => handleTeamCountChange(e.target.value)}
									placeholder={
										validationErrors.teamCount
											? validationErrors.teamCount
											: t("calculate.enterTeamCount")
									}
									className={`option-button number-option ${
										validationErrors.teamCount ? "error-placeholder" : ""
									}`}
								/>

								<div
									className={`option-button ${
										teamCount === "OPTION_UNKNOWN" ? "active" : ""
									}`}
									onClick={() => handleTeamCountChange("OPTION_UNKNOWN")}
								>
									<span>{t("calculate.optionUnknown")}</span>
								</div>
							</div>
						</div>
					</SlideIn>
				</div>
			)}

			{step === 6 && (
				<div className="slide">
					<div className="slide-container2">
						<SlideIn direction="up" delay={70} threshold={0.1}>
							<div className="service-container">
								<h2>{t("calculate.eventRequirementsQuestion")}</h2>
								<div className="service-option__container">
									{[
										{
											name: SERVICE_NAMES.GAME_ORGANIZATION,
											displayName: t("services.gameOrganizationAndRefereeing"),
											info: t("services.gameOrganizationAndRefereeingInfo"),
											subServices: [
												{
													name: SUB_SERVICE_NAMES.REGULATIONS,
													displayName: t("services.regulations"),
												},
												{
													name: SUB_SERVICE_NAMES.SERVER_INFRASTRUCTURE,
													displayName: t("services.serverInfrastructure"),
												},
												{
													name: SUB_SERVICE_NAMES.REFEREES,
													displayName: t("services.referees"),
												},
												{
													name: SUB_SERVICE_NAMES.TEAM_CURATORS,
													displayName: t("services.teamCurators"),
												},
											],
										},
										{
											name: SERVICE_NAMES.BROADCASTING,
											displayName: t("services.broadcasting"),
											info: t("services.broadcastingInfo"),
											subServices: [
												{
													name: SUB_SERVICE_NAMES.BROADCAST_GRAPHICS,
													displayName: t("services.broadcastGraphics"),
												},
												{
													name: SUB_SERVICE_NAMES.UNIQUE_GAME_HUD,
													displayName: t("services.uniqueGameHUD"),
												},
												{
													name: SUB_SERVICE_NAMES.LOGO_PLACEMENT_2D,
													displayName: t("services.2DLogoPlacement"),
												},
												{
													name: SUB_SERVICE_NAMES.BRAND_LOGOS_3D,
													displayName: t("services.3DBrandLogos"),
												},
												{
													name: SUB_SERVICE_NAMES.COMMENTARY_STUDIO,
													displayName: t("services.commentaryStudioSelection"),
												},
											],
										},
									].map((service) => (
										<div key={service.name}>
											<SlideIn direction="up" delay={70} threshold={0.1}>
												<div
													className={`service-option main-service ${
														validationErrors.services ? "error" : ""
													}`}
												>
													<div
														className={`custom-checkbox ${
															services[service.name] ? "active" : ""
														} ${validationErrors.services ? "error" : ""}`}
														onClick={() => handleServiceToggle(service.name)}
													>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 64 64"
															width="32px"
															height="32px"
														>
															<path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
														</svg>
													</div>
													<span
														className="option-name main-service-name"
														data-tooltip={service.info}
														onClick={(e) => showTooltip(e, service.info)}
													>
														{service.displayName}
													</span>
													<span
														className="question-mark"
														data-tooltip={service.info}
														onClick={(e) => showTooltip(e, service.info)}
													>
														<i className="bi bi-question-circle"></i>
													</span>
												</div>
											</SlideIn>
											{services[service.name] && (
												<SlideIn direction="right" delay={50}>
													<div className="sub-services">
														<p className="sub-services-title">
															{t("calculate.additionalOptions")}
														</p>
														<ul className="sub-services-list">
															{service.subServices.map((subService, index) => (
																<li key={index}>
																	<div className="service-option sub-service">
																		<div
																			className={`custom-checkbox ${
																				additionalOptions[service.name]?.[
																					subService.name
																				]
																					? "active"
																					: ""
																			} ${
																				validationErrors.services ? "error" : ""
																			}`}
																			onClick={() =>
																				handleAdditionalOptionToggle(
																					service.name,
																					subService.name
																				)
																			}
																		>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				viewBox="0 0 64 64"
																				width="28px"
																				height="28px"
																			>
																				<path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
																			</svg>
																		</div>
																		<span className="option-name">
																			{subService.displayName}
																		</span>
																	</div>
																</li>
															))}
														</ul>
													</div>
												</SlideIn>
											)}
										</div>
									))}
								</div>
							</div>
						</SlideIn>
						<SlideIn direction="up" delay={100} threshold={0.1}>
							<div className="cost__container">
								<p className="cost-name">
									{t("calculate.estimatedCost")} {totalCost}{" "}
									{t("calculate.currency")}
								</p>
								<button
									onClick={(e) => {
										if (Object.values(services).every((v) => !v)) {
											e.preventDefault();
											setValidationErrors((prev) => ({
												...prev,
												services: "Please select at least one service",
											}));
										} else {
											setShowContactForm(true);
										}
									}}
									className="paragraph-button"
								>
									{t("calculate.sendBrief")}
								</button>
							</div>
						</SlideIn>
					</div>
				</div>
			)}

			<div className="navigation">
				{step > 1 && (
					<button
						className="arrow-button arrow-button--prev"
						onClick={handlePrevious}
					>
						<div className="arrow arrow--left">
							<div className="arrow-top"></div>
							<div className="arrow-bottom"></div>
						</div>
					</button>
				)}
				{step < 6 && (
					<button
						className={`arrow-button arrow-button--next ${
							isOptionActive ? "animate-arrow" : ""
						}`}
						onClick={handleNext}
					>
						<div className="arrow arrow--right">
							<div className="arrow-top"></div>
							<div className="arrow-bottom"></div>
						</div>
					</button>
				)}
			</div>

			{showContactForm && (
				<>
					<div
						className="overlay"
						onClick={() => setShowContactForm(false)}
					></div>
					<div className="popup">
						{!isSubmitted ? (
							<>
								<h2>{t("calculate.contactDetails")}</h2>
								<div className="contact-options">
									<input
										type="text"
										placeholder={t("calculate.name")}
										required
										value={name}
										onChange={(e) => setName(e.target.value)}
										className={validationErrors.name ? "error" : ""}
									/>
									<input
										type="email"
										placeholder="Email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										className={validationErrors.email ? "error" : ""}
									/>
									<input
										type="text"
										placeholder="Telegram ID"
										value={telegram}
										onChange={(e) => {
											const value = e.target.value.replace(
												/[^a-zA-Z0-9_@]/g,
												""
											);
											setTelegram(value);
										}}
										onFocus={(e) => {
											if (!e.target.value) {
												setTelegram("@");
											}
										}}
										onBlur={(e) => {
											if (e.target.value === "@") {
												setTelegram("");
											}
										}}
									/>
									<input
										type="tel"
										placeholder={t("calculate.phone")}
										value={phone}
										onChange={(e) => {
											const value = e.target.value.replace(/[^0-9+]/g, "");
											setPhone(value);
										}}
										onFocus={handlePhoneFocus}
									/>
									<textarea
										maxLength="256"
										placeholder={t("calculate.additionalInfo")}
										value={additionalInfo}
										onChange={(e) => setAdditionalInfo(e.target.value)}
									></textarea>
								</div>
								<button className="submit-button" onClick={handleSubmit}>
									{t("calculate.submit")}
								</button>
							</>
						) : (
							<div className="success-message">
								<i className="bi bi-check-circle"></i>
								<p>{t("calculate.successMessage")} </p>
								<p>
									{" "}
									{t("calculate.redirectMessage")} {counter}{" "}
									{t("calculate.seconds")}...
								</p>
								{counter === 0 && navigate("/")}
							</div>
						)}
						<button
							onClick={() => {
								setShowContactForm(false);
								setIsSubmitted(false);
							}}
							className="close-button"
						>
							<i className="bi bi-x"></i>
						</button>
					</div>
				</>
			)}
		</section>
	);
}

export default Calculate;
